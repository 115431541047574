import styled from '@emotion/styled';
import { Link } from "gatsby";
import { baseColors, breakpoints } from '../../constants';
import { deriveRgbaFromHex } from '../../utils';

export const Wrapper = styled.nav`
  max-width: 750px;
  margin: 2rem auto;
  border-top: 1px solid ${baseColors.grey.base};
  border-bottom: 1px solid ${baseColors.grey.base};
`;

export const Inner = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${breakpoints.bp1}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ListItem = styled.li`
  display: flex;

  @media screen and (min-width: ${breakpoints.bp1}) {
    ${props => props.isAlignedRight && 'text-align: right;'}
  }

  &:first-of-type {
    border-bottom: 1px solid ${baseColors.grey.base};

    @media screen and (min-width: ${breakpoints.bp1}) {
      border-bottom: 0;
      border-right: 1px solid ${baseColors.grey.base};
    }
  }
`;

export const ListLink = styled(Link)`
  width: 100%;
  padding: 1rem;
  text-decoration: none;

  &:hover {
    background: ${baseColors.grey.light};

    > span:last-child {
      text-decoration-color: ${deriveRgbaFromHex(baseColors.black, .4)};
    }
  }
`;

export const Label = styled.span`
  display: block;
  font-size: .6rem;
  color: ${baseColors.grey.dark};
`;

export const Title = styled.span`
  display: block;
  font-size: .8rem;
  line-height: 1.3;
  text-decoration: underline;
  text-decoration-color: ${deriveRgbaFromHex(baseColors.black, .15)};
`;
