import { selectTagsFromPost, selectCategoryFromPost } from './selectPosts';

export const selectPageContent = ({
  markdownRemark: {
    fields: { slug = '' } = {},
    frontmatter: {
      title = '',
      datetime = '',
      excerpt = '',
      author = '',
      category = {},
      tags = []
    } = {},
    html = '',
    id = ''
  } = {}
}) => ({
  id,
  datetime,
  title,
  path: slug,
  excerpt,
  author,
  tags: selectTagsFromPost(tags),
  category: selectCategoryFromPost(category),
  html
});
