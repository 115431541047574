import React from 'react';
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Banner from '../components/Banner';
import ContentArea from '../components/ContentArea';
import PostPagination from '../components/PostPagination';
import { selectPageContent } from '../selectors/selectPageContent';
import { selectPostPagination } from '../selectors/selectPosts';

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...PAGE_CONTENT_FRAGMENT
    }
  }
`;

const PostTemplate = ({ data, location: { href }, pageContext: { pagination } }) => {
  const { title, datetime, excerpt, author, category, tags, html } = selectPageContent(data);
  const { prev, next } = selectPostPagination(pagination);

  return (
    <PageLayout>
      <MetaTags url={href} metaTitle={title} metaDescription={excerpt} />
      <Banner
        title={title}
        excerpt={excerpt}
        datetime={datetime}
        author={author}
        category={category}
        tags={tags}
      />
      <ContentArea dangerouslySetInnerHTML={{ __html: html }} />
      <PostPagination prev={prev} next={next} />
    </PageLayout>
  );
};

export default PostTemplate;
