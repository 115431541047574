import styled from '@emotion/styled';
import { baseColors, breakpoints } from '../../constants';
import { deriveRgbaFromHex } from '../../utils';

const ContentArea = styled.div`
  max-width: 750px;
  margin: 0 auto;
  color: ${baseColors.grey.dark};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5rem;

    .anchor {
      border: 0;
    }

    .anchor svg {
      opacity: 0;
      fill: ${baseColors.grey.dark};
      transition: opacity .15s ease-in-out;
    }

    &:hover .anchor svg {
      opacity: 1;
    }
  }

  a {
    color: ${baseColors.link.base};
    text-decoration: underline;
    text-decoration-color: ${deriveRgbaFromHex(baseColors.link.base, .15)};
    border: 0;
    transition: all .15s ease-in-out;

    &:hover {
      color: ${baseColors.link.dark};
      text-decoration-color: ${deriveRgbaFromHex(baseColors.link.base, .45)};
    }
  }

  ul, ol {
    margin: 1rem 0 1.5rem 0;
    padding-left: 1.5rem;

    p + ul,
    p + ol {
      margin-top: -.5rem;
    }
  }

  blockquote {
    margin: 1rem 0 2rem 0;
    padding: 1rem;
    background: ${baseColors.link.light};
    border-left: .5rem solid ${baseColors.link.base};

    @media screen and (min-width: ${breakpoints.bp3}) {
      margin: 2rem -3rem;
      padding: 2rem 3rem;
    }

    cite {
      font-size: .8rem;
      font-style: normal;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  figure {
    margin: 1rem 0 2rem 0;
    background: ${baseColors.grey.light};

    @media screen and (min-width: ${breakpoints.bp3}) {
      margin: 1rem -3rem 2rem -3rem;
    }
    
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      padding: 1rem;
    }
  }

  figcaption {
    padding: .5rem;
    font-size: .7rem;
    font-style: italic;
    color: ${baseColors.black};
    background: ${baseColors.grey.base};
  }

  .twitter-tweet,
  .cp_embed_iframe {
    margin: 1rem auto !important;
  }

  iframe[src*="https://www.youtube.com"] {
    width: 100%;
  }
`;

export default ContentArea;
