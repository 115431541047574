import React from 'react';
import {
  Wrapper,
  Inner,
  ListItem,
  ListLink,
  Label,
  Title
} from './styled';

const PostPagination = ({
  prev,
  next
}) => (
  <Wrapper>
    <Inner>
      <ListItem>
        {
          prev && prev.path && prev.title &&
          <ListLink to={prev.path}>
            <Label>Previous post:</Label>
            <Title>{prev.title}</Title>
          </ListLink>
        }
      </ListItem>
      <ListItem isAlignedRight>
        {
          next && next.path && next.title &&
          <ListLink to={next.path}>
            <Label>Next post:</Label>
            <Title>{next.title}</Title>
          </ListLink>
        }
      </ListItem>
    </Inner>
  </Wrapper>
);

export default PostPagination;
